<template>
  <JoszakiPage
    :page-name="pageName"
    :tracking-params="trackingParams"
    class="PriceCalculatorPage"
    inner-class="grid gap-5 grid-cols-1 lg:grid-cols-[3fr_1fr]"
  >
    <template v-if="isInitialized">
      <div class="lg:pr-5 lg:border-r lg:border-r-gray-300 flex flex-col gap-5">
        <h1 class="lg:text-xl font-bold mb-[-0.75rem]">
          {{
            $t("priceCalculator.title", {
              professionTaskName: $helpers.capitalize(professionTask.name),
            })
          }}
        </h1>

        <PriceCalculatorStep />

        <JoszakiCard v-if="showPriceCalculatorDescription" type="primary">
          <MarkdownTextBlock :text="description" />
        </JoszakiCard>
        <JoszakiCard v-if="showRelatedContentBox">
          <MarkdownTextBlock :text="relatedContent" />
        </JoszakiCard>
      </div>
      <div class="lg:pt-14">
        <StepIndicator class="hidden lg:block" />

        <JoszakiCard v-if="showProfessionBox" class="lg:mt-20">
          <NuxtLink :to="professionalsListPagePath" target="_blank">
            {{ $helpers.capitalize(profession.name ?? "") }}
            {{ $t("search.professionResult.professionals") }}
          </NuxtLink>
          <p class="text-xs text-typo-secondary font-semibold">
            {{
              $t("search.professionResult.resultCount", {
                count: profession?.activeProfessionalCount ?? 0,
              })
            }}
          </p>
        </JoszakiCard>
        <BlockTender
          v-if="showSideBlockTender"
          text-input
          class="md:grid-cols-1 mt-5"
          @requestTender="
            onRequestTender({
              source: 'price-calculator-right',
              description: $event,
            })
          "
        />
      </div>
    </template>
    <div v-else>
      <h1 class="text-2xl lg:text-4xl font-bold text-primary">
        {{ $t("priceCalculator.fallbackTitle") }}
      </h1>
      <p v-if="error">
        {{ $t("priceCalculator.notFound") }}
      </p>
    </div>
  </JoszakiPage>
</template>

<router>
{
name: 'priceCalculatorPage'
}
</router>

<script>
import { mapGetters } from "vuex";
import { useRequestTender } from "~/composables/requestTender";
import StepIndicator from "~/components/_refactored/priceCalculatorPage/StepIndicator/StepIndicator.vue";
import MarkdownTextBlock from "~/components/_refactored/common/MarkdownTextBlock.vue";
import PriceCalculatorStep from "~/components/_refactored/priceCalculatorPage/PriceCalculatorStep/PriceCalculatorStep.vue";
import BlockTender from "~/components/_refactored/blocks/BlockTender.vue";
import PRICE_CALCULATOR_SSR from "~/graphql/priceCalculatorSSR.graphql";
import JoszakiPage from "~/components/_refactored/common/JoszakiPage.vue";
import { STEP_TYPE_SELECT } from "~/store/priceCalculator";
import { EVENT_PAGE_NAMES } from "~/data/pageNames";

export default {
  name: "PriceCalculatorPage",
  components: {
    JoszakiPage,
    BlockTender,
    PriceCalculatorStep,
    MarkdownTextBlock,
    StepIndicator,
  },
  layout: ({ route }) => {
    if (route.query?.referrer === "kh") {
      return "MinimalLayout";
    }
    return "DefaultLayout";
  },
  setup() {
    return {
      pageName: EVENT_PAGE_NAMES.PRICE_CALCULATOR_PAGE,
      ...useRequestTender(),
    };
  },
  async asyncData({ route, store, $query, redirect, localePath }) {
    const professionTaskSeoName = route.params.professionTask;

    const step = route.params.step;

    if (step && process.server) {
      redirect(
        301,
        localePath({
          name: "priceCalculatorPage",
          params: {
            professionTask: professionTaskSeoName,
          },
        })
      );
    }

    const isInitialized = store.getters["priceCalculator/isInitialized"];
    const professionTaskSeoNameFromStore =
      store.getters["priceCalculator/professionTaskSeoName"];
    if (
      !isInitialized ||
      professionTaskSeoNameFromStore !== professionTaskSeoName
    ) {
      try {
        const { priceCalculatorByProfessionTaskSeoName } = await $query(
          PRICE_CALCULATOR_SSR,
          { professionTaskSeoName }
        );
        await store.dispatch(
          "priceCalculator/setPriceCalculator",
          priceCalculatorByProfessionTaskSeoName
        );
      } catch (e) {
        await store.commit("priceCalculator/setError", true);
      }
    }
  },
  data() {
    return {
      trackingParams: null,
    };
  },
  head() {
    const title = this.professionTask
      ? this.$helpers.capitalize(
          this.$t("priceCalculator.headTitle", {
            professionTaskName: this.professionTask?.name,
          })
        )
      : this.$t("priceCalculator.fallbackHeadTitle");

    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: `${this.$helpers.topDomain}${this.$route.fullPath}`,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: "/images/common/szaki.png",
        },
        {
          hid: "description",
          name: "description",
          content: this.description ?? "",
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.description ?? "",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: `${this.$helpers.topDomain}${this.$route.path}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("priceCalculator", [
      "stepSeoNames",
      "currentStep",
      "currentStepIndex",
      "currentStepSeoName",
      "currentStepOptions",
      "nextStepSeoName",
      "isInitialized",
      "error",
      "professionTask",
      "profession",
      "description",
      "relatedContent",
    ]),
    isKH() {
      return this.$route.query?.referrer === "kh";
    },
    showRelatedContentBox() {
      if (this.isKH || !this.relatedContent || this.$branding.isSuperprofikCz) {
        return false;
      }

      return this.currentStepIndex === 0 || !this.nextStepSeoName;
    },
    showPriceCalculatorDescription() {
      if (this.isKH || !this.description) {
        return false;
      }

      return this.currentStepIndex === 0;
    },
    showProfessionBox() {
      return !this.isKH && this.profession && !this.$branding.isSuperprofikCz;
    },
    showSideBlockTender() {
      return !this.isKH && !this.$branding.isSuperprofikCz;
    },
    professionalsListPagePath() {
      return this.localePath({
        name: "professionPage",
        params: {
          profession: this.profession?.seoName,
        },
      });
    },
  },
  beforeMount() {
    this.trackingParams = {
      professionTaskSeoName: this.professionTask?.seoName,
      stepIndex: this.currentStepIndex,
      referrer: this.$route.query?.referrer ?? null,
    };

    if (!process.server) {
      const stepSeoName = this.$route.params.step;
      this.$store.dispatch("priceCalculator/setStepBySeoName", stepSeoName);
      if (this.currentStep?.type === STEP_TYPE_SELECT) {
        const currentStepOptionIds = this.currentStepOptions.map(
          (option) => option.id
        );
        if (!currentStepOptionIds.includes(this.currentStep.selectedOptionId)) {
          this.$store.dispatch("priceCalculator/setSelectedOptionById", {
            stepSeoName: this.currentStepSeoName,
            optionId:
              currentStepOptionIds.length > 0 ? currentStepOptionIds[0] : -1,
          });
        }
      }
    }
  },
  methods: {
    onRequestTender({ source, description }) {
      this.requestTender({
        source,
        description,
      });
    },
  },
};
</script>

<style lang="scss">
.kh {
  .bg-primary,
  .bg-info {
    background-color: #00aeef !important;
  }

  .text-primary {
    color: #00aeef !important;
  }

  .border-primary,
  .border-info {
    border-color: #00aeef !important;
  }

  .hover\:bg-info-darker:hover {
    background-color: #0e8ecb !important;
  }

  .\!text-info-darker {
    color: #0e8ecb !important;
  }
}
</style>
