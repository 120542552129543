<template>
  <JoszakiCard class="SizeInputBox">
    <div class="flex flex-row justify-between items-center gap-2">
      <span class="text-lg font-bold">
        <template v-if="showIndex">{{ index + 1 }}.</template> {{ entityName }}
      </span>
      <span v-if="entityCount > 1" @click="removeEntity">
        <IconComponent
          icon="times-circle"
          class="!text-2xl text-primary inline hover:cursor-pointer hover:text-primary-dark"
        />
      </span>
    </div>
    <div
      v-for="(input, idx) in inputs"
      :key="idx"
      :class="{ 'pt-1': input.type === 'checkbox' }"
    >
      <JoszakiInput
        v-if="input.type === 'number'"
        type="number"
        :step="0.1"
        :label="input.label"
        :unit="input?.properties?.unit ?? null"
        :value="entity[input.variableName]"
        :disabled="isInputDisabled(input)"
        :precision="2"
        :show-error-msg="false"
        @input="(value) => onInput(input.variableName, value)"
        @focusout="(event) => onInputFocusOut(input, event)"
      />
      <label v-if="input.type === 'checkbox'" class="font-bold">
        <input
          type="checkbox"
          :disabled="isInputDisabled(input)"
          :checked="entity[input.variableName] ? 'checked' : null"
          @change="
            (event) => onInput(input.variableName, event.target.checked ? 1 : 0)
          "
        />
        {{ input.label }}
      </label>
    </div>
  </JoszakiCard>
</template>

<script>
export default {
  name: "SizeInputBox",
  props: {
    entityName: {
      type: String,
      required: true,
    },
    inputs: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    entityCount: {
      type: Number,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onInput(variableName, value) {
      this.$emit("input", {
        variableName,
        value,
        entityIndex: this.index,
      });
    },
    onInputFocusOut(input) {
      if (input.type === "number") {
        const maxValue = input?.properties?.max;
        const currentValue = this.entity[input.variableName];
        const clampedValue = maxValue
          ? Math.min(maxValue, currentValue)
          : currentValue;

        this.$emit("input", {
          variableName: input.variableName,
          value: clampedValue,
          entityIndex: this.index,
        });
      }
    },
    removeEntity() {
      this.$emit("remove", this.index);
    },
    isInputDisabled(input) {
      if (input.properties?.disabledIf) {
        return !!this.entity[input.properties.disabledIf];
      }

      if (input.properties?.enabledIf) {
        return !this.entity[input.properties.enabledIf];
      }

      return false;
    },
  },
};
</script>
