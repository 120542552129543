<template>
  <JoszakiCard class="ResultBox border-primary border-2">
    <div class="flex flex-row gap-5 justify-between">
      <img
        class="hidden md:block max-h-24"
        v-lazy-load data-src="~/assets/joszaki-head.svg"
        alt=""
      />
      <div class="flex-auto">
        <p class="text-2xl font-bold text-primary">
          <span class="hidden md:inline">{{
            $t("priceCalculator.resultBox.title")
          }}</span>
          <span class="md:hidden">{{
            $t("priceCalculator.resultBox.titleMobile")
          }}</span>
        </p>
        <p class="hidden md:block">
          {{ $t("priceCalculator.resultBox.description") }}
        </p>
        <p class="md:hidden">
          {{ $t("priceCalculator.resultBox.descriptionMobile") }}
        </p>
        <p class="hidden md:block">
          <IconComponent icon="check" class="text-green-500 inline" />
          {{ $t("priceCalculator.resultBox.location") }}
        </p>
      </div>
    </div>
    <ResultRow :row="selectedOptionsRow" is-open-by-default />
    <ResultRow
      v-if="results.entities?.length > 1 && results?.totals"
      :row="results?.totals"
      is-open-by-default
    />
    <div class="py-4 grid lg:grid-cols-2">
      <ResultRow
        v-for="(entity, entityIndex) in results?.entities ?? []"
        :key="entityIndex"
        :row="entity"
        :is-open-by-default="results.entities?.length === 1"
      />
    </div>
    <div
      v-if="!$branding.isSuperprofikCz"
      class="flex flex-col md:flex-row w-full justify-items-stretch gap-5"
    >
      <JoszakiButton
        v-if="!isKh && profession"
        inverted
        outline
        expanded
        @click="gotoProfessionalPage"
      >
        {{
          $t("priceCalculator.resultBox.viewProfessionals", {
            professionName: profession.name,
          })
        }}
      </JoszakiButton>
      <JoszakiButton
        expanded
        :inverted="isKh"
        :outline="isKh"
        @click="onRequestTender"
      >
        {{ $t("priceCalculator.resultBox.requestTender") }}
      </JoszakiButton>
    </div>
  </JoszakiCard>
</template>

<script>
import { mapGetters } from "vuex";
import { useRequestTender } from "~/composables/requestTender";
import ResultRow from "~/components/_refactored/priceCalculatorPage/ResultRow/ResultRow.vue";

export default {
  name: "ResultBox",
  components: { ResultRow },
  props: {
    results: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ...useRequestTender(),
    };
  },
  computed: {
    ...mapGetters("priceCalculator", [
      "professionTask",
      "profession",
      "selectedOptionNames",
    ]),
    selectedOptionsRow() {
      return {
        label: this.$t("priceCalculator.resultBox.selectedOptions"),
        rows: this.selectedOptionNames.map((optionName) => ({
          label: optionName,
        })),
      };
    },
    isKh() {
      return this.$route.query?.referrer === "kh";
    },
  },
  methods: {
    gotoProfessionalPage() {
      if (!this.profession) {
        return;
      }

      const professionalsListPagePath = this.localePath({
        name: "professionPage",
        params: {
          profession: this.profession.seoName,
        },
      });
      window.open(professionalsListPagePath);
    },
    onRequestTender() {
      this.requestTender({
        source: "price-calculator-result",
        profession: this.profession,
      });
    },
  },
};
</script>
