var render = function render(){var _vm=this,_c=_vm._self._c;return _c('JoszakiCard',{staticClass:"BlockTender grid grid-cols-1 relative gap-2",class:{
    'md:grid-cols-2': !_vm.collapsed,
  },attrs:{"type":_vm.invertColors ? 'default' : 'primary'}},[_c('div',{staticClass:"z-19"},[_c('div',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(_vm.title ?? _vm.$t("tenderBlock.title"))+" "),(_vm.collapsedByDefault)?_c('span',{staticClass:"float-right h-7 w-7 cursor-pointer flex items-center justify-center",class:{
          'md:hidden': !_vm.collapsed,
        },on:{"click":_vm.toggleCollapsed}},[_c('IconComponent',{attrs:{"icon":_vm.collapsed ? 'angle-down' : 'angle-up'}})],1):_vm._e()]),(!_vm.collapsed)?_c('p',{staticClass:"text-sm whitespace-pre-wrap"},[_vm._v(" "+_vm._s(_vm.subTitle ?? _vm.$t("tenderBlock.subTitle"))+" ")]):_vm._e()]),(!_vm.collapsed)?_c('div',{staticClass:"flex flex-col justify-between"},[(_vm.collapsedByDefault)?_c('div',{staticClass:"hidden md:flex self-end h-7 w-7 cursor-pointer items-center justify-center text-xl lg:text-2xl font-bold",on:{"click":_vm.toggleCollapsed}},[_c('IconComponent',{attrs:{"icon":_vm.collapsed ? 'angle-down' : 'angle-up'}})],1):_vm._e(),(_vm.textInput && !_vm.multiLine)?_c('JoszakiInputOld',{staticClass:"mb-0 mt-0",attrs:{"icon-left":"pen","placeholder":_vm.placeholder ?? _vm.$t('tenderBlock.placeholder')},on:{"focusin":function($event){return _vm.$trackers.trackClick('BlockTenderInput', _vm.trackingData)}},model:{value:(_vm.tenderText),callback:function ($$v) {_vm.tenderText=$$v},expression:"tenderText"}}):_vm._e(),(_vm.textInput && _vm.multiLine)?_c('JoszakiTextAreaOld',{staticClass:"text-black",attrs:{"placeholder":_vm.placeholder ?? _vm.$t('tenderBlock.placeholder')},model:{value:(_vm.tenderText),callback:function ($$v) {_vm.tenderText=$$v},expression:"tenderText"}}):_vm._e(),_c('JoszakiButton',{staticClass:"w-full",class:{
        '': !_vm.textInput,
        'mt-2': _vm.textInput,
      },attrs:{"type":"info","inverted":"","outline":_vm.invertColors},on:{"click":_vm.requestTender}},[_vm._v(" "+_vm._s(_vm.$t(`tenderBlock.requestButton`))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }