<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="MarkdownTextBlock" v-html="$md.render(text)" />
</template>

<script>
export default {
  name: "MarkdownTextBlock",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.MarkdownTextBlock {
  text-align: justify;
  &.bg-primary {
    p,
    h1,
    h2,
    ul,
    ol,
    p *,
    h1 *,
    h2 *,
    ul *,
    ol * {
      color: white !important;
    }
  }

  h1 {
    text-align: left;
    font-size: 2rem;
    padding: 0.5rem 0 0.25rem 0;
  }

  h2 {
    text-align: left;
    font-size: 1.5rem;
    padding-bottom: 0.25rem;
  }

  ul {
    list-style: disc inside;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }

  p {
    padding-bottom: 0.5rem;
  }
}
</style>
