<template>
  <section class="StepIndicator">
    <p class="text-2xl">
      {{ $t("priceCalculator.stepIndicatorInfo", { stepsCount }) }}
    </p>
    <ol class="list-decimal pl-4 pt-8">
      <li
        v-for="(step, index) in stepNames"
        :key="index"
        :class="{
          'text-primary font-bold': step.seoName === currentStepSeoName,
        }"
      >
        {{ step.name }}
      </li>
    </ol>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StepIndicator",
  computed: {
    ...mapGetters("priceCalculator", [
      "stepNames",
      "currentStepSeoName",
      "stepsCount",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
