<!-- eslint-disable vue/no-v-html -->
<template>
  <JoszakiCard
    class="SelectableBox cursor-pointer"
    :class="{ 'bg-amber-100': isSelected, 'border-amber-500': isSelected }"
    @click.native="$emit('onselected', item.id)"
  >
    <div class="flex flex-row justify-between gap-1 items-center mb-1">
      <span
        class="flex justify-center items-center border border-gray-300 p-2 bg-white"
        :class="{
          'rounded-full w-[44px] h-[44px]': !isWideImage,
          'rounded-md': isWideImage,
        }"
      >
        <img
          v-if="item.imageUrl"
          class="object-cover"
          :class="{ 'h-[26px]': !isWideImage, 'h-[50px]': isWideImage }"
          alt=""
          v-lazy-load :data-src="item.imageUrl"
        />
        <IconComponent v-if="!item.imageUrl" icon="wrench" class="!text-xl" />
      </span>
      <div
        class="text-lg font-bold lg:hidden flex-1"
        :class="{ hidden: isWideImage }"
      >
        {{ item.label }}
      </div>
      <IconComponent
        v-if="isSelected"
        icon="check-square"
        class="!text-4xl text-amber-500"
      />
      <IconComponent
        v-else
        :icon="['far', 'square']"
        class="!text-4xl text-gray-300"
      />
    </div>
    <div class="text-lg font-bold" :class="{ 'hidden lg:block': !isWideImage }">
      {{ item.label }}
    </div>
    <div v-html="$md.render(item.description)" />
  </JoszakiCard>
</template>

<script>
export default {
  name: "SelectableBox",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["onselected"],
  computed: {
    isWideImage() {
      return this.item.variables?.isWideImage;
    },
  },
};
</script>

<style lang="scss" scoped></style>
