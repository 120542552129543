<template>
  <div :class="`ResultRow level-${level}`">
    <div
      class="flex justify-between"
      :class="{ 'row-with-value': 'value' in row }"
    >
      <span
        class="text-primary font-bold flex flex-row gap-1 items-center"
        :class="{ 'cursor-pointer': filteredSubRows.length > 0 }"
        @click="toggleIsOpen()"
      >
        <IconComponent
          v-if="filteredSubRows.length > 0"
          :icon="isOpen ? 'angle-down' : 'angle-right'"
        />
        {{ row.label }}
      </span>
      <span v-if="value" :class="isTotalRow ? 'font-bold' : ''">
        {{ value }}{{ `&nbsp;${row?.unit}` ?? "" }}
      </span>
    </div>
    <ResultRow
      v-for="(subRow, subRowIndex) in filteredSubRows"
      :key="subRowIndex"
      :row="subRow"
      :class="{ hidden: !isOpen }"
      :level="level + 1"
    />
  </div>
</template>

<script>
export default {
  name: "ResultRow",
  props: {
    row: {
      type: Object,
      required: true,
    },
    isOpenByDefault: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data: (instance) => ({
    isOpen: instance.isOpenByDefault,
  }),
  computed: {
    filteredSubRows() {
      if (!this.row.rows) {
        return [];
      }

      if (this.level === 1) {
        return this.row.rows;
      }

      return this.row.rows.filter(
        (subRow) => !("value" in subRow) || subRow.value > 0
      );
    },
    value() {
      if (!("value" in this.row)) {
        return null;
      }

      if (!this.row.unit) {
        return this.row.value;
      }

      if (this.row.unit !== process.env.CURRENCY) {
        return this.$helpers.localeFormatNumber(this.row.value, 2);
      }

      const maxCurrencyValue = Math.floor((this.row.value * 1.1) / 100) * 100;

      if (this.row.value === maxCurrencyValue) {
        return this.$helpers.localeFormatNumber(this.row.value, 0);
      }

      return `${this.$helpers.localeFormatNumber(
        this.row.value,
        0
      )} - ${this.$helpers.localeFormatNumber(maxCurrencyValue, 0)}`;
    },
    isTotalRow() {
      return this.row.label === "Összesen";
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.ResultRow {
  &.level-1 {
    font-size: 1.25rem;
  }
  &.level-2 {
    font-size: 1.125rem;
    padding-left: 1rem;
  }
  &.level-3 {
    font-size: 1rem;
    padding-left: 1rem;
  }

  .row-with-value {
    position: relative;
    span {
      background: white;
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0.4rem;
      width: 100%;
      height: 0;
      line-height: 0;
      border-bottom: 2px dotted #ddd;
    }
  }
}
</style>
